import React from "react";
import VideoBackground from "../components/VideoBackground";
import HomeSectionGallery from "../components/HomeSectionGallery";
import HomeSectionAboutUs from "../components/HomeSectionAboutUs";

function Home() {
    return (
        <div>
            <VideoBackground/>
            <HomeSectionGallery/>
            <HomeSectionAboutUs/>
        </div>
    );
}

export default Home;
