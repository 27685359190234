import rightArrow from "../assets/icons/right-arrow.svg"
import searchIcon from "../assets/icons/search-icon.svg";

export default function HomeSectionAboutUs () {
    return (
        <div className="bg-primary flex flex-col mt-[159px] px-[310px]">
            <h2 className="h2-secondary">
                APPROCCIO PERSONALIZZATO, VISIONE GLOBALE:
            </h2>
            <h2 className="h1-primary">
                questo è <i>Downtown</i>
            </h2>
            <div className="flex mt-[143px] mb-[128.5px] justify-between space-x-[200px]">
                <div className="flex items-start">
                    <p className="text-left">
                        Non avrai a che fare con un franchising, nel quale ogni agenzia lavora a sé e per sé.
                    </p>
                    <img src={rightArrow} alt="Right arrow" className="mt-1 ms-[20px]"/>
                </div>
                <div className="flex flex-col">
                    <p className="text-left">
                        Quello di Downtown è un mondo interconnesso nel quale le informazioni circolano liberamente
                        all’interno della rete, con l’obiettivo di avere accesso ad un database di proprietà esclusive
                        consultabile da ogni parte del globo, per proporti l’immobile ideale in base alle tue
                        esigenze.<br/><br/>I nostri broker sono a supporto l’uno dell’altro e ciò significa che puoi
                        contare sull’esperienza e sulla consulenza di ognuno di loro, pur trovandoti a parlare sempre
                        con la stessa persona, con la quale instaurerai un rapporto di totale
                        fiducia.<br/><br/>Questo è ciò che ci differenzia da tutti gli altri. Insieme alla volontà di
                        farti vivere un’esperienza indimenticabile già durante il momento di valutazione della
                        proprietà, prima dell’acquisto.
                    </p>
                    <div className="flex justify-end w-full">
                        <button
                            className="btn-form mt-[60px] w-1/3 text-right"
                        >
                            <div className="flex justify-between">
                                <span>APPROFONDISCI</span>
                                <img src={searchIcon} alt="search"/>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
