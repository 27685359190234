import logoFooter from '../assets/logos/logo-footer-cutted.svg'
import searchIcon from "../assets/icons/search-icon.svg";

export default function Footer () {
    return (
        <footer className="flex flex-col mt-[87px]">
            <div className="flex px-[305px] justify-between">
                <div className="flex flex-col justify-between max-w-[306px]">
                    <div>
                        <span className="font-raleway font-bold text-[18px] tracking-[1.8px] text-secondary uppercase">
                            firenze
                        </span>
                        <h6 className="h6-secondary mt-6">
                            direzione@downtown.it
                        </h6>
                    </div>
                    <h4 className="h4-secondary mt-[71px]">
                        vuoi parlare con un nostro personal advisor?
                    </h4>
                </div>
                <div className="flex flex-col justify-between max-w-[306px]">
                    <div>
                        <span className="font-raleway font-bold text-[18px] tracking-[1.8px] text-secondary uppercase">
                            roma
                        </span>
                        <h6 className="h6-secondary mt-6">
                            roma@downtown.it
                        </h6>
                    </div>
                    <button
                        className="btn-form mt-9"
                    >
                        <div className="flex justify-between">
                            <span>contattaci</span>
                            <img src={searchIcon} alt="search"/>
                        </div>
                    </button>
                </div>
                <div className="flex flex-col justify-between max-w-[306px]">
                    <div>
                        <span className="font-raleway font-bold text-[18px] tracking-[1.8px] text-secondary uppercase">
                            milano
                        </span>
                        <h6 className="h6-secondary mt-6">
                            milano@downtown.it
                        </h6>
                    </div>
                    <button
                        className="btn-form mt-9"
                    >
                        <div className="flex justify-between">
                            <span>lavora con noi</span>
                            <img src={searchIcon} alt="search"/>
                        </div>
                    </button>
                </div>
                <div className="flex flex-col justify-end max-w-[306px]">
                    <h6 className="h6-secondary">
                        instagram | facebook
                    </h6>
                </div>
            </div>
            <img src={logoFooter} alt="Logo Footer"/>
            <div className="h-[67px] bg-darkGray px-[305px] flex justify-between items-center">
                <h6 className="h6-secondary">
                    copyright
                </h6>
                <h6 className="h6-secondary">
                    credits
                </h6>
            </div>
        </footer>
    );
}
