import {useState} from "react";
import * as emailjs from "emailjs-com";
import searchIcon from "../assets/icons/search-icon.svg";

export default function PropertySectionContact () {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        language: '',
        country: '',
        message: '',
        checkbox: false,
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length === 0) {
            // Invia l'email solo se il form è valido
            emailjs.sendForm(
                'YOUR_SERVICE_ID',  // Sostituisci con il tuo service ID
                'YOUR_TEMPLATE_ID',  // Sostituisci con il tuo template ID
                e.target,             // Passiamo l'evento per inviare i dati del form
                'YOUR_USER_ID'       // Sostituisci con il tuo user ID
            )
                .then(
                    (result) => {
                        alert('Messaggio inviato con successo!');
                        setFormData({
                            firstName: '',
                            lastName: '',
                            email: '',
                            phone: '',
                            language: '',
                            country: '',
                            message: '',
                            checkbox: false,
                        });
                    },
                    (error) => {
                        alert('Errore nell\'invio del messaggio!');
                    }
                );
        } else {
            setErrors(validationErrors);
        }
    };



    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = "Nome è obbligatorio.";
        if (!formData.lastName) newErrors.lastName = "Cognome è obbligatorio.";
        if (!formData.email) newErrors.email = "Email è obbligatoria.";
        if (!formData.phone) newErrors.phone = "Telefono è obbligatorio.";
        if (!formData.language) newErrors.language = "Lingua è obbligatoria.";
        if (!formData.country) newErrors.country = "Paese è obbligatorio.";
        if (!formData.message) newErrors.message = "Messaggio è obbligatorio.";
        if (!formData.checkbox) newErrors.checkbox = "Devi accettare i termini.";
        return newErrors;
    };

    return(
        <div className="px-[298px] mt-[120px] text-center">
            <h4 className="h4-primary">
                Richiedi informazioni
            </h4>
            <div className="mt-[60px] mb-[71px]">
                <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
                    <div className="flex justify-between mb-7">
                        <div className="flex flex-col">
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                placeholder="Nome"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                            {errors.firstName && <p className="text-left text-red-500">{errors.firstName}</p>}
                        </div>
                        <div className="flex flex-col">
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                placeholder="Cognome"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                            {errors.lastName && <p className="text-left text-red-500">{errors.lastName}</p>}
                        </div>
                    </div>
                    <div className="flex justify-between mb-7">
                        <div className="flex flex-col">
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {errors.email && <p className="text-left text-red-500">{errors.email}</p>}
                        </div>
                        <div className="flex flex-col">
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                placeholder="Telefono"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                            {errors.phone && <p className="text-left text-red-500">{errors.phone}</p>}
                        </div>
                    </div>
                    <div className="flex justify-between mb-7">
                        <div className="flex flex-col">
                            <input
                                id="language"
                                name="language"
                                placeholder="Lingua"
                                value={formData.language}
                                onChange={handleChange}
                            >
                            </input>
                            {errors.language && <p className="text-left text-red-500">{errors.language}</p>}
                        </div>
                        <div className="flex flex-col">
                            <input
                                id="country"
                                name="country"
                                placeholder="Paese"
                                value={formData.country}
                                onChange={handleChange}
                            >
                            </input>
                            {errors.country && <p className="text-left text-red-500">{errors.country}</p>}
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <textarea
                            id="message"
                            name="message"
                            placeholder="Messagio"
                            value={formData.message}
                            onChange={handleChange}
                            className="w-full"
                            rows="5"
                        ></textarea>
                        {errors.message && <p className="text-left text-red-500">{errors.message}</p>}
                    </div>
                    <div className="flex flex-col">
                        <div className="mt-[30px] text-left flex items-start">
                            <input
                                type="checkbox"
                                id="checkbox"
                                name="checkbox"
                                checked={formData.checkbox}
                                onChange={handleChange}
                                className="mt-1 me-3"
                            />
                            <span className="font-raleway font-normal test-[14px] tracking-[0] text-lightGray">
                            *Si autorizza la : Professionecasa Spa, laddove ne ricorrano i presupposti, all’elaborazione dei dati personali, ai sensi del D.L.vo 196/2003, inteso che l’azienda si riserva di raccogliere, trattare, comunicare e diffondere, per finalità istituzionali, i dati personali nei limiti e secondo le disposizioni di legge, di regolamento o atto amministrativo.
                        </span>
                        </div>
                        {errors.checkbox && <p className="text-left text-red-500">{errors.checkbox}</p>}
                    </div>
                    <div className="flex justify-end">
                        <button type="submit" className="btn-form mt-[30px] text-end">
                            <div className="flex justify-between">
                                <span className="me-8">Invia messsaggio</span>
                                <img src={searchIcon} alt="search"/>
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
