import React, { useRef, useState } from "react";
import videoSrc from "../assets/videos/video-background-home.mp4";
import HomePropertySearchForm from "./HomePropertySearchForm";

export default function VideoBackground () {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoPlay = () => {
    if (!isPlaying) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
      <div
          className="relative h-screen w-full overflow-hidden"
          onClick={handleVideoPlay}
      >
        <video
            ref={videoRef}
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={videoSrc}
            loop
            muted
            playsInline
        />

        <div
            className="relative flex items-center justify-center h-full bg-darkGray bg-opacity-65">
          <div className="relative flex flex-col justify-center items-center h-full w-full box">
            <span className="title">
              Live the luxury.
            </span>
            <span className="sub-title">
              FEEL BETTER.
            </span>
            <div className="absolute bottom-[73px] px-[110px] w-full">
              <HomePropertySearchForm/>
            </div>
          </div>
        </div>
      </div>
  );
};
