import {useState} from "react";
import {useNavigate} from "react-router-dom";

import searchIcon from "../assets/icons/search-icon.svg"
import {PropertyTypology} from "../constants/property.typology.ts";


export default function HomePropertySearchForm () {
    const navigate = useNavigate();

    const [country, setCountry] = useState('');
    const [location, setLocation] = useState('');
    const [typology, setTypology] = useState('');
    const [lifestyle, setLifestyle] = useState('');
    const [maxPrice, setMaxPrice] = useState('');

    const handleClick = () => {
        const queryParams = new URLSearchParams();

        // if (country) queryParams.append('country', country);
        // if (location) queryParams.append('location', location);
        if (typology) queryParams.append('typology', typology);
        // if (lifestyle) queryParams.append('lifestyle', lifestyle);
        if (maxPrice) queryParams.append('maxPrice', maxPrice);

        navigate(`/properties?${queryParams.toString()}`);
    };

    return (
        <div className="flex justify-between w-full items-start space-x-2">
            <input
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder="Paese"
            />
            <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Location"
            />
            <select value={typology} onChange={(e) => setTypology(e.target.value)}>
                <option value={null}>Tipologia</option>
                {Object.entries(PropertyTypology).map(([key, typology]) => (
                    <option key={key} value={typology.value}>
                        {typology.name}
                    </option>
                ))}
            </select>
            <select value={lifestyle} onChange={(e) => setLifestyle(e.target.value)}>
                <option value="">Lifestyle</option>
                <option value="urban">Urbano</option>
                <option value="rural">Rurale</option>
            </select>
            <div className="flex flex-col">
                <input
                    type="text"
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                    placeholder="Prezzo"
                />
                <button
                    onClick={handleClick}
                    className="btn-form mt-9"
                >
                    <div className="flex justify-between">
                        <span>Cerca</span>
                        <img src={searchIcon} alt="search" />
                    </div>
                </button>
            </div>
        </div>
    );
}
