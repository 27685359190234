export const PropertyTypology = {
    APARTMENT: {
        value: 'apartment',
        name: 'Appartamento',
    },
    PENTHOUSE: {
        name: 'Attico',
        value: 'penthouse'
    },
    FARMHOUSE: {
        value: 'farmhouse',
        name: 'Casa colonica'
    },
    TOWNHOUSE: {
        value: 'townhouse',
        name: 'Casa di città'
    },
    LUXURY_HOUSE: {value: 'luxuryHouse', name: 'Casa di lusso'},
    COTTAGE: {value: 'cottage', name: 'Cottage'},
    SINGLE_FAMILY_HOUSE: {value: 'singleFamilyHouse', name: 'Casa unifamigliare'},
    CASTLE: {value: 'castle', name: 'Castello'},
    CHALET: {value: 'chalet', name: 'Chalet'},
    RESIDENTIAL_COMPLEX: {value: 'residentialComplex', name: 'Complesso residenziale'},
    COTTAGE_HOUSE: {value: 'cottageHouse', name: 'Cottage'},
    DWELLING: {value: 'dwelling', name: 'Dimora'},
    DUPLEX: {value: 'duplex', name: 'Duplex'},
    FARM: {value: 'farm', name: 'Fattoria'},
    ISLAND: {value: 'island', name: 'Isola'},
    LOFT: {value: 'loft', name: 'Loft'},
    PALACE: {value: 'palace', name: 'Palazzo'},
    RUSTIC_HOUSE: {value: 'rusticHouse', name: 'Rustico o casale'},
    ESTATE: {value: 'estate', name: 'Tenuta'},
    LAND: {value: 'land', name: 'Terreno'},
    VILLA: {value: 'villa', name: 'Villa'},
    MULTI_FAMILY_VILLA: {value: 'multiFamilyVilla', name: 'Villa plurifamigliare'},
    TOWNHOUSE_VILLA: {value: 'townhouseVilla', name: 'Villetta a schiera'},
}