import {BrowserRouter, Route, Routes} from "react-router-dom";

import Home from "./pages/Home";
import Properties from "./pages/Properties";
import Property from "./pages/Property";
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  return (
      <BrowserRouter>
          <div className="flex flex-col min-h-screen">
              <Header />
              <main className="flex-grow">
                  <Routes>
                      <Route path="/" element={<Home/>}/>
                      <Route path="/properties" element={<Properties/>}/>
                      <Route path="/property/:propertyId" element={<Property/>}/>
                  </Routes>
              </main>
              <Footer />
          </div>
      </BrowserRouter>
);
}

export default App;
