import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import imgFallback from "../assets/images/image-fallback.webp";
import shareIcon from "../assets/icons/share-icon.svg";
import PropertySectionTab from "../components/PropertySectionTab";
import PropertySectionGallery from "../components/PropertySectionGallery";
import PropertySectionContact from "../components/PropertySectionContact";

export default function Property() {
    const {propertyId} = useParams();
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const url = process.env.REACT_APP_API_URL;

    useEffect(() => {
        async function getProperty() {
            const response = await fetch(`${url}/public-apis/property/${propertyId}`);
            const resData = await response.json();
            setProperty(resData);
            setLoading(false);
        }
        getProperty();
    }, [propertyId, url]);

    const photoBgSrc = property?.photos?.length > 0 ? property.photos[0].path : imgFallback;

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <section
                className="relative w-full max-w-[1920px] mx-auto"
                style={{aspectRatio: '16 / 9'}}
            >
                <div
                    className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
                    style={{
                        backgroundImage: `
                    linear-gradient(180deg, #1A1A1A00 0%, #040506 100%),
                    url(${photoBgSrc})`
                    }}
                ></div>
                <div className="box relative h-full flex items-end">
                    <div className="w-full px-[198px]">
                        <div className="flex justify-between">
                        <span
                            className="font-raleway text-left text-[18px] tracking-[1.8px] text-gray uppercase">{property?.city}</span>
                            <button
                                className="font-raleway text-left text-[18px] tracking-[1.8px] text-gray uppercase flex">{property?.reference}
                                <img className="ms-4 mt-1" src={shareIcon} alt="share"/>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <PropertySectionTab description={property?.propertyDescription} area={property?.area} outdoorArea={property?.outdoorArea} price={property?.price}  />
            <PropertySectionGallery photos={property?.photos} />
            <PropertySectionContact />
        </div>
    )
}
