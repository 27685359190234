import searchIcon from "../assets/icons/search-icon.svg";
import {useEffect, useState} from "react";
import logoElement from "../assets/images/logo-element.svg"

export default function HomeSectionGallery() {
    const [availableProperties, setAvailableProperties] = useState([]);
    const url = process.env.REACT_APP_API_URL;

    // Fetch properties
    useEffect(() => {
        const fetchProperties = async () => {
            const queryParams = new URLSearchParams({
                page: 1,
                limit: 6,
            });
            const response = await fetch(`${url}/public-apis/fetchProperties?${queryParams.toString()}`);
            const resData = await response.json();
            setAvailableProperties(resData);
        };
        fetchProperties().then();
    }, [url]);


    return (
        <div className="bg-primary flex flex-col relative">
            <div className="absolute right-0">
                <img src={logoElement} alt="Logo element"/>
            </div>
            <div className="px-[304px] pt-[220px]">
                <h1 className="h1-secondary">
                    DOWNTOWN
                </h1>
                <h1 className="h1-primary">
                    Il <i>tuo</i> concetto di abitare
                </h1>
                <div className="flex mt-[33px] space-x-[16px]">
                    <p className="text-left w-1/2">
                        Lusso è poter contare su qualcuno che sappia ascoltarti, capirti, interpretare i tuoi desideri.
                        I
                        Personal Advisor di Downtown fanno esattamente questo: comprendono la tua idea di abitare e
                        trovano
                        la soluzione immobiliare perfetta per farti vivere l’esperienza che stai cercando.
                    </p>
                    <p className="text-left w-1/2">
                        Abbiamo broker in tutto il mondo, che si coordinano tra di loro per poter avere una visione
                        globale
                        e sempre aggiornata delle proprietà da proporre. Tu avrai un solo referente, ma in realtà a
                        lavorare
                        per te sarà l’intero team
                    </p>
                </div>
                <button
                    className="btn-form mt-[60px] w-1/5"
                >
                    <div className="flex justify-between">
                        <span>SCOPRI DI PIÙ</span>
                        <img src={searchIcon} alt="search"/>
                    </div>
                </button>
                <div className="flex items-end justify-between mt-[221.5px]">
                    <h3 className="h3">
                        Proprietà esclusive, <i>per vivere in un sogno</i>
                    </h3>
                    <button
                        className="btn-form w-1/5 mb-2"
                    >
                        <div className="flex justify-between">
                            <span>SCOPRI DI PIÙ</span>
                            <img src={searchIcon} alt="search"/>
                        </div>
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-3 grid-rows-2 gap-[30px] mt-[90px]">
                {availableProperties?.docs?.map((item, index) => {
                    const photo = item.photos[0]
                    return (
                        <div
                            key={index}
                        >
                            <img
                                src={photo.path}
                                alt={`item-${index}`}
                                className="w-full h-[600px] object-cover"
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    )
}
