import {Link, useLocation, useNavigate} from "react-router-dom";
import headerLogo from '../assets/logos/logo-header.svg'

export default function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    const isActive = (path) => location.pathname === path;

    const onLogoClick = () => {
        navigate(`/`)
    }

    return (
        <header className="absolute top-0 z-10 box w-full">
            <nav className="container mx-auto flex justify-start">
               <img src={headerLogo} alt="Header logo" className="cursor-pointer me-[300px]"
                    onClick={() => onLogoClick()} />
                <ul className="flex justify-start items-center space-x-4">
                    <li>
                        <Link
                            to="/properties"
                            className={`hover:font-bold ${
                                isActive('/properties') ? 'font-bold' : 'font-normal'
                            }`}
                        >
                            proprietà
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    )
}
