import {useSearchParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import PropertiesList from "../components/PropertiesList";
import searchIcon from "../assets/icons/search-icon.svg";
import {PropertyTypology} from "../constants/property.typology.ts";

export default function Properties() {
    const [availableProperties, setAvailableProperties] = useState([]);
    const url = process.env.REACT_APP_API_URL;
    const [searchParams] = useSearchParams();

    const [country, setCountry] = useState('');
    const [location, setLocation] = useState('');
    const [typology, setTypology] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [reference, setReference] = useState('');
    const [lifestyle, setLifestyle] = useState('');
    const [sort, setSort] = useState('');

    // Function to fetch properties
    const fetchProperties = useCallback(async (params = {}) => {
        const filteredParams = Object.entries(params)
            .filter(([_, value]) => value != null && value !== '')
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        const queryParams = new URLSearchParams({
            page: 1,
            limit: 20,
            ...filteredParams,
        });

        const response = await fetch(`${url}/public-apis/fetchProperties?${queryParams.toString()}`);
        const resData = await response.json();
        setAvailableProperties(resData);
    }, [url]);


    // Fetch properties based on query params
    useEffect(() => {
        const typologyParam = searchParams.get('typology');
        const maxPriceParam = searchParams.get('maxPrice');

        if (typologyParam) setTypology(typologyParam);
        if (maxPriceParam) setMaxPrice(maxPriceParam);

        fetchProperties({
            typology: typologyParam,
            maxPrice: maxPriceParam,
        }).then();
    }, [fetchProperties, searchParams]);

    // Fetch properties when clicking "Cerca"
    const handleSearch = () => {
        fetchProperties({
            country,
            location,
            typology,
            maxPrice,
            reference,
            lifestyle,
            sort,
        }).then();
    };



    return (
        <div className="px-[300px] pt-[283px]">
            <h1 className="h1-primary">
                Immobili
            </h1>
            <div className="my-[87px] flex flex-wrap justify-between w-full items-start">
                <input
                    type="text"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="Paese"
                    className="w-1/6"
                />
                <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="Location"
                    className="w-1/6"
                />
                <select value={typology} onChange={(e) => setTypology(e.target.value)}>
                    <option value=''>Tipologia</option>
                    {Object.entries(PropertyTypology).map(([key, typology]) => (
                        <option key={key} value={typology.value}>
                            {typology.name}
                        </option>
                    ))}
                </select>
                <input
                    type="text"
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                    placeholder="Prezzo"
                    className="w-1/6"
                />
                <input
                    type="text"
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                    placeholder="Cod. Riferimento"
                    className="w-1/5"
                />
                <div className="mt-10 flex justify-between w-full">
                    <div className="flex w-1/2 space-x-7">
                        <select value={lifestyle} onChange={(e) => setLifestyle(e.target.value)} className="w-1/3">
                            <option value="">Lifestyle</option>
                            <option value="urban">Urbano</option>
                            <option value="rural">Rurale</option>
                        </select>
                        <button className="btn-form" onClick={handleSearch}>
                            <div className="flex justify-between">
                                <span className="me-5">Cerca</span>
                                <img src={searchIcon} alt="search"/>
                            </div>
                        </button>
                    </div>
                    <select
                        className="sort-select"
                        value={sort} onChange={(e) => setSort(e.target.value)}>
                        <option value="">Ordina per</option>
                        <option value="price">Prezzo crescente</option>
                        <option value="-price">Prezzo decrescente</option>
                    </select>
                </div>
            </div>
            <PropertiesList properties={availableProperties.docs}/>
        </div>
    )
}
