import {useState} from "react";

export default function PropertySectionTab({description, area, outdoorArea, price}) {
    const Tab = ({label, active, onClick, addClass}) => (
        <button
            className={`font-raleway text-gray uppercase ${addClass}
      ${active ? 'font-bold' : 'hover:font-bold'}`}
            onClick={onClick}
        >
            {label}
        </button>
    );

    const Tabs = ({tabs}) => {
        const [activeTab, setActiveTab] = useState(tabs[0].label);

        return (
            <div className="mt-40 px-[298px]">
                <div className="mb-40 flex justify-end">
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.label}
                            label={tab.label}
                            active={activeTab === tab.label}
                            addClass={tab.addClass}
                            onClick={() => setActiveTab(tab.label)}
                        />
                    ))}
                </div>
                <div className="mt-4">
                    {tabs.find((tab) => tab.label === activeTab)?.content}
                </div>
            </div>
        );
    };

    return (
        <div>
            <Tabs
                tabs={[
                    {
                        label: 'proprietà',
                        content:
                            <p className="max-w-[985px] font-raleway text-[18px] tracking-[0] text-gray font-normal">
                                {description}
                            </p>,
                        // addClass: 'me-10'
                    },
                    // {label: 'location', content: <div>Contenuto della Tab 2</div>, addClass: 'me-10'},
                    // {label: 'planimetria', content: <div>Contenuto della Tab 3</div>},
                ]}
            />
            <div className="flex w-100 mt-[50px] px-[298px]">
                <div className="flex flex-col me-[224px]">
                                <span className="font-raleway text-[18px] tracking-[1.8px] text-gray uppercase">
                                    interni
                                </span>
                    <h4 className="h4-primary text-nowrap">
                        {area ? `${area} mq` : '-'}
                    </h4>
                </div>
                <div className="flex flex-col me-[224px]">
                                <span className="font-raleway text-[18px] tracking-[1.8px] text-gray uppercase">
                                    esterni
                                </span>
                    <h4 className="h4-primary text-nowrap">
                        {outdoorArea ? `${outdoorArea} mq` : '-'}
                    </h4>
                </div>
                <div className="flex flex-col">
                                <span className="font-raleway text-[18px] tracking-[1.8px] text-gray uppercase">
                                    prezzo
                                </span>
                    <h4 className="h4-primary text-nowrap">
                        {price ? `${price} €` : '-'}
                    </h4>
                </div>
            </div>
        </div>
    )
}
