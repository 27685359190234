import imgFallback from '../assets/images/image-fallback.webp'

export default function PropertyImage({ photos, alt }) {
    const getImageSrc = () => {
        if (!photos || photos.length === 0) {
            return imgFallback;
        }
        return photos.length > 0 ? photos[0].path : imgFallback;
    };

    return (
        <img
            src={getImageSrc()}
            alt={alt}
            onError={(e) => (e.target.src = imgFallback)}
            className="w-full h-[500px] object-cover mb-10"
        />
    );
}
